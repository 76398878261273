import React from "react"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import * as s from "./Container.module.scss"
const cn = classNames.bind(s)

const Container = ({ children, isFluid = false }) => {
  return <div className={cn("container", { isFluid })}>{children}</div>
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  isFluid: PropTypes.bool,
}

export default Container
