import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

const ThemeMode = ({ theme, children }) => {
  return (
    <>
      <Helmet htmlAttributes={{ "data-theme": theme }} />
      {children}
    </>
  )
}

ThemeMode.propTypes = {
  theme: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ThemeMode
