import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import useStore from "context/ZustandStore"

import ThemeMode from "components/ThemeMode"
import Header from "components/Header"
import Cursor from "components/Cursor"

import * as s from "./Layout.module.scss"

const Layout = ({ children }) => {
  const currentTheme = useStore(s => s.currentTheme)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={s.layout}>
      <ThemeMode theme={currentTheme}>
        <Header />
        <Cursor />
        <main>{children}</main>
      </ThemeMode>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
