import React, { memo } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import useStore from "context/ZustandStore"

import CursorType from "components/Cursor/CursorType"
import Container from "components/Container"

import * as s from "./Header.module.scss"

const toggleTheme = () => {
  const isDark = useStore.getState().currentTheme === "dark"
  useStore.setState(state => ({
    currentTheme: isDark ? "light" : "dark",
  }))
}

const Header = () => {
  return (
    <motion.header
      className={s.header}
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -72, opacity: 0 }}
      transition={{ duration: 1.5, ease: [0.83, 0, 0.17, 1] }}
    >
      <Container>
        <div className={s.logo}>
          <CursorType type={"hovered"}>
            <Link to="/">HELL</Link>
          </CursorType>
          <CursorType type={"pointer"}>
            <span onClick={toggleTheme}></span>
          </CursorType>
        </div>
        <div className={s.menu}>
          <CursorType type={"hovered"}>
            <button>
              <span></span>
              <span></span>
            </button>
          </CursorType>
        </div>
      </Container>
    </motion.header>
  )
}

export default memo(Header)
