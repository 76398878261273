// extracted by mini-css-extract-plugin
export const background = "#fff";
export const foreground = "#000";
export const themeRed = "#ea291e";
export const medium = "500px";
export const desktop = "1000px";
export const large = "1400px";
export const cursor = "Cursor-module--cursor--tTJ1B";
export const pointer = "Cursor-module--pointer--zdV5j";
export const dot = "Cursor-module--dot--3BVj6";
export const hovered = "Cursor-module--hovered--3--qs";