import React, { useCallback } from "react"
import PropTypes from "prop-types"
import useStore from "context/ZustandStore"

const CursorType = ({ type, children }) => {
  const setCursorType = useStore(state => state.setCursorType)

  const updateCursorType = useCallback(() => {
    setCursorType(type)
  }, [type])

  const resetCursorType = useCallback(() => {
    setCursorType(false)
  }, [])

  return (
    <div onMouseEnter={updateCursorType} onMouseLeave={resetCursorType} style={{display: 'inline-block'}}>
      {children}
    </div>
  )
}

CursorType.propTypes = {
  type: PropTypes.oneOf(["pointer", "hovered", false]),
}

export default CursorType
