import React, { useRef, useEffect, useCallback } from "react"
import { motion, useMotionValue } from "framer-motion"
import classNames from "classnames/bind"

import useStore from "context/ZustandStore"

import lerp from "lib/lerp"

import * as s from "./Cursor.module.scss"
const cn = classNames.bind(s)

const _ = {
  lerpFactor: 0.2,
  precision: 0.2,
}

const isWindowDefined = typeof window !== "undefined"

const Cursor = () => {
  const cursorType = useStore(state => state.cursorType)
  const setCursorPosition = useStore(state => state.setCursorPosition)
  const x = useMotionValue(isWindowDefined ? window.innerWidth / 2 : 0)
  const targetX = useMotionValue(0)
  const y = useMotionValue(isWindowDefined ? window.innerHeight / 2 : 0)
  const targetY = useMotionValue(0)
  const isAnimating = useRef(false)

  const onMouseMove = e => {
    targetX.set(e.clientX)
    targetY.set(e.clientY)

    !isAnimating.current && isWindowDefined && rAF()
  }

  const rAF = useCallback(() => {
    isAnimating.current = true

    x.set(lerp(x.get(), targetX.current, _.lerpFactor))
    y.set(lerp(y.get(), targetY.current, _.lerpFactor))

    if (
      Math.abs(y.get() - targetY.current) > _.precision ||
      Math.abs(x.get() - targetX.current) > _.precision
    ) {
      requestAnimationFrame(rAF)
    } else {
      cancelAnimationFrame(rAF)
      isAnimating.current = false
    }
  }, [targetX, targetY])

  useEffect(() => {
    x.onChange(val => setCursorPosition(val, y.get()))
    y.onChange(val => setCursorPosition(x.get(), val))
  }, [])

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove)
    return () => document.removeEventListener("mousemove", onMouseMove)
  }, [])

  return (
    <motion.div
      className={cn("cursor", cursorType)}
      style={{ x, y }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        type: "tween",
        duration: 1,
        delay: 0.5,
        ease: [0, 0, 0, 1],
      }}
    >
      <div className={cn("dot")}></div>
    </motion.div>
  )
}

export default Cursor
